import { LbLogoLoading, useClient, useModules, usePermissions } from "@lb/frontend";
import { RouterProvider } from "react-router-dom";
import router from "./router";

export default function App() {
  const { permissions } = usePermissions();
  const { client } = useClient();
  const { modules } = useModules();

  return (
    <RouterProvider
      {...{ router: router({ permissions, client, modules }), fallbackElement: <LbLogoLoading /> }}
    />
  );
}
